$('a[href^="#"]').on('click', function(event) {
    event.preventDefault()
    
    var sc = $(this).attr("href"),
        dn = $(sc).offset().top - $('header.site-header').height()
    $('html, body').animate({scrollTop: dn}, 700)
})

$('.to-top').click(function(){
    var scroll = $(window).scrollTop(),
        speed = scroll / 2

    $('html, body').stop().animate({scrollTop: 0}, speed)
})

$(document).on('click', '.focusOn', function(){
    var elem = $(this)
    var target = $(elem.data('item'))
    var top = target.offset().top

    $('html, body').stop().animate({scrollTop: top - 90}, 600, () =>{
        target.focus()
    })
})