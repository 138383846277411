var delivery_price = 0,
    branch = '';

function updateCart() {
    $('.page-wrapper').addClass('loading');
    simpleAjax('get_cart_info', function (res) {
        var info = JSON.parse(res)
        var quantity = info['quantity']
        var price = info['price']
        var old_price = info['old_price']
        var discount = info['discount']
        var delivery = info['delivery']
        var cart_items = info['cart_items']
        var promo_form = info['promo_form']
        var orderBtn = $('.order-btn')
        var cartBlock = $('.cart-block')

        $('[data-count]').attr('data-count', quantity);

        if(quantity){
            cartBlock.find('h6').text('Ваша корзина (' + quantity + ')')
            cartBlock.find('p').text('Всего товаров на ' + price + ' тг.')
            orderBtn.attr('disabled', false)
        }else{
            cartBlock.find('h6').text('Ваша корзина пуста')
            cartBlock.find('p').text('Всего товаров на 0 тг.');
            orderBtn.attr('disabled', true)
        }

        $('.cart-quantity').text(quantity)
        $('.cart-price').text(price.toLocaleString())
        $('.delivery-price').text(delivery.toLocaleString())
        $('.cart-total-price').text((price + delivery).toLocaleString())

        $('.promo-wrapper').html(promo_form)

        $('.cart-items').html(cart_items);
        $('.page-wrapper').removeClass('loading');
    })
}

function addToCart(id, quantity) {
    var btns = $('.add-to-cart[data-id="' + id + '"]');

    btns.attr('disabled', true);
    requestAjax('add_to_cart', {
        id,
        quantity
    }, function () {
        addNotif('Корзина', 'Товар добавлен в корзину', 'success', '/cart');
        updateCart();
        btns.attr('disabled', false);
    });
}

$(document).on('click', '.add-to-cart', function () {
    var elem = $(this),
        id = elem.data('id'),
        quantity = $('.quantity-field input[data-id="' + id + '"]');

    if (quantity.length) {
        quantity = parseInt(quantity.val());
    } else {
        quantity = 1;
    }

    addToCart(id, quantity);
})

$(document).on('click', '.clean-cart', function () {
    var btns = $('.clean-cart');

    btns.attr('disabled', true);
    simpleAjax('clean_cart', function () {
        btns.attr('disabled', false);
        updateCart();
    })
})

function removeFromCart(id) {
    requestAjax('remove_from_cart', id, function () {
        updateCart();
    })
}

$(document).on('click', '.remove-cart-item', function () {
    var elem = $(this),
        id = elem.data('id');

    removeFromCart(id);
})

var timer;

$(document).on('click', '.change-quantity', function () {

    var elem = $(this),
        input = elem.siblings('input'),
        id = input.data('id'),
        val = parseInt(input.val())

    if (isNaN(val)) {
        val = 1;
    }

    clearTimeout(timer)

    val = Math.round(val);

    if (elem.hasClass('plus')) {
        val++
    } else if (elem.hasClass('minus')) {
        val--
    }

    if (val < 1) {
        val = 1;
    } else if (val > 100) {
        val = 100;
    }

    input.val(val)

    res = {
        quantity: val,
        id: id
    }

    timer = setTimeout(() => {
        requestAjax('change_quantity', res, function () {
            $('.cart-block').addClass('loading')
            updateCart();
        })
    }, 500);

})

$(document).on('click', '#send_promo', function (e) {
    var elem = $(this),
        input = elem.siblings('input'),
        wrapper = elem.parents('.form-group'),
        val = input.val();

    input.each(inputCheck)
    e.preventDefault();

    if (!wrapper.hasClass('error')) {
        input.attr('readonly', true);
        input.blur();
        elem.attr('disabled', true);
        $('.cart-block').addClass('loading');

        requestAjax('set_promo', val, function (res) {
            if (res == 'success') {
                updateCart();
            } else {
                elem.attr('disabled', false);
                wrapper.addClass('error');
                input.attr('readonly', false);
                addNotif('Ошибка', 'Промокод не найден', 'error');
                $('.cart-block').removeClass('loading');
            }
        });
    }
})

$(document).on('click', '#delete_promo', function (e) {
    var elem = $(this),
        input = elem.siblings('input');

    e.preventDefault();
 
    input.attr('readonly', true);
    input.blur();
    elem.attr('disabled', true);
    $('.cart-block').addClass('loading');

    requestAjax('delete_promo', '', function (res) {
        updateCart();
    });
})

if($('section.cart').length){
    simpleAjax('get_search_products', function(res){
        res = JSON.parse(res)

        const input = $('#search_product')

        input.autocomplete({
            source: (request, response) => {
                const term = $.trim(request.term).toLowerCase()
                const results = []

                $.each(res, (key, value) => {
                    const title = $.trim(value.title).toLowerCase()

                    if(title.indexOf(term) != -1){
                        results.push(value)
                    }
                })

                response(results.slice(0, 5))
            },
            select: (ui, item) => {
                const itemId = item.item.id
                addToCart(itemId, 1)
                input.blur()
            },
            minLength: 0,
            appendTo: '.search-product'
        }).focus(() => {
            input.autocomplete('search', input.val());
        }).data('uiAutocomplete')._renderItem = (ul, item) => {
            return $('<li>').addClass('search-product-item').append(`
                <div class="image" style="background-image: url(` + item.image + `)"></div>
                <div class="item-info">
                    <h6>` + item.title + `</h6>
                    <p class="grey-text sm-text fw-500">` + item.price + `</p>
                </div>
                `
            ).appendTo( ul )
        }
    })
}