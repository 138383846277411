$('.order-btn').click(function(){
    var elem = $(this)
    var form = $('.order-form')
    var required = form.find('.required')

    required.each(inputCheck)

    if(!form.find('.error').length){
        elem.attr('disabled', true)

        var results = pickInputs(form)
        
        requestAjax('add_order', results, function(res){
            res = parseInt(res)

            if(res){
                window.location.href = '/success/?order_id=' + res
            }else{
                addNotif('Ошибка', 'Что-то пошло не так, попробуйте перезагрузить страницу', 'error')
            }

        })
    }
})

$('#send').click((event) => {
    const elem = $('#send')
    const form = $('#form')
    const required = form.find('.required')

    event.preventDefault()

    required.each(inputCheck)

    if(!form.find('.error').length){
        elem.attr('disabled', true)

        const results = pickInputs(form)

        requestAjax('add_mail', results, (res) => {
            const inputs = form.find('input, textarea')

            addNotif('Спасибо', 'Ваше письмо было отпавлено', 'success')

            closePopup()
            inputs.each(resetInput)
        })
    }
})

$('#coop_send').click((event) => {
    const elem = $('#coop_send')
    const form = $('#coop_form')
    const required = form.find('.required')

    event.preventDefault()

    required.each(inputCheck)

    if(!form.find('.error').length){
        elem.attr('disabled', true)

        const results = pickInputs(form)

        requestAjax('add_mail', results, (res) => {
            const inputs = form.find('input, textarea')

            addNotif('Спасибо', 'Ваше письмо было отпавлено', 'success')

            closePopup()
            inputs.each(resetInput)
        })
    }
})

$('#send_review').click((event) => {
    const elem = $('#send_review')
    const form = $('#review_form')
    const required = form.find('.required')

    event.preventDefault()

    required.each(inputCheck)

    if(!form.find('.error').length){
        elem.attr('disabled', true)

        const results = pickInputs(form)

        requestAjax('add_reviews', results, (res) => {
            const inputs = form.find('input, textarea')
            elem.attr('disabled', false)
            addNotif('Спасибо', 'Ваш отзыв отправлен на модерацию', 'success')
            inputs.each(resetInput)
        })
    }
})