$('.fs-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slide: '.slide'
}).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var tabs = $('.slider-nav .slide-btn');

    tabs.removeClass('active')
    tabs.eq(nextSlide).addClass('active')
});

$('.catalog-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
})

$('.sidebar-slider').slick({
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
})

$(document).on('click', '.slider-nav .slide-btn:not(.active)', function(){
    var elem = $(this),
        index = elem.index()

    $('.fs-slider').slick('slickGoTo', index)
})

$('.products-slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    slide: '.product-item',
    pauseOnFocus: false,
    pauseOnHover: false,
})