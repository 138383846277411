$('#save_edit').click(function(e){
    var elem = $(this),
        form = $('.fields'),
        inputs = form.find('.required'),
        password = $('#edit_password'),
        confirm = $('#edit_password_confirm')

    if(password.val() != ''){
        inputs.push(confirm[0])
    }

    e.preventDefault()
    inputs.each(inputCheck)
    
    if(!form.find('.error').length){
        var results = pickInputs(form, true);

        elem.attr('disabled', true).addClass('loading');

        sendAjax('edit_profile', results, function(res){
            elem.attr('disabled', false).removeClass('loading');
            addNotif('Обновление', 'Ваши данные успешно сохранены', 'success');

            setTimeout(function(){
                window.location.reload()
            }, 1000)
        },function(res){
            process_error(res)
            elem.attr('disabled', false).removeClass('loading')
        })

    }
})