window.set_where = index => {
    const wrapper = $('.page-wrapper')
    const items = $('.where-adresses')
    
    wrapper.addClass('loading')

    requestAjax('get_addresses', index, res => {
        res = JSON.parse(res)

        window.mapCity = res.city
        window.mapPoints = res.points
        setNewCoords()
        items.html(res.structure)
        wrapper.removeClass('loading')
    })
}

$(document).on('click', '.map-address', function() {
    const elem = $(this)
    const index = elem.index()
    focusCoords(index)
    elem.addClass('active').siblings().removeClass('active')
})