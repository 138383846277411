if($('#login_form').length){

    $('#login_confirm').click(function(e){
        var elem = $(this),
            form = $('#login_form');
    
        e.preventDefault();
        
        form.find('.error').removeClass('error');
        form.find('.required:visible').each(inputCheck);
    
        if(form.find('.form-group.error').length == 0){
            var results = pickInputs(form);

            if($('#login_type_phone').prop('checked')){
                delete(results.email);
            }else{
                delete(results.phone);
            }
    
            form.find('.message').remove();
    
            elem.attr('disabled', true);
    
            sendAjax('login_user', results, function(res){
                window.location.href = '/dashboard';
            },function(res){
                displayErrors(res.text_error);
                elem.attr('disabled', false);
            });
        }
    })

}