function openPopup(popup, video){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeIn(400)
}

function closePopup(){
    $('.popup').stop().fadeOut(400)
}

$('.order-btn').click(function(){
    openPopup('order-popup')
})

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})



$(document).on('click', '.close-image-popup', function(){
    $('.image-popup').fadeOut(300);
})

$(document).on('click', '.popup-img', function(){
    var elem = $(this),
        src = elem.data('url');

    $('.image-popup').fadeIn(300).find('.image-wrapper').html('<img src="' + src + '">');
})

$('.mail-btn').click(() => {
    const popup = $('.mail-popup')

    popup
        .stop()
        .fadeIn(300)
})

$('.coop-btn').click(() => {
    const popup = $('.coop-popup')

    popup
        .stop()
        .fadeIn(300)
})