
$(function () {
    $('.tab-content  li').first().addClass('active');
})

$(document).on('click', '.tab-btn:not(.active)', function() {
    var elem = $(this),
        parent = elem.parent('li'),
        slider = $('.popular-slider'),
        slug = elem.data('slug');
        
        $('.tab-btn').removeClass('active')

        var tabs = $('.tab-content li');
        tabs.removeClass('active');
     
        parent.addClass('active'); 
        elem.addClass('active')

        products = $('.products');
        products.addClass('loading');

        requestAjax('get_items_by_category', slug, function(res){
            products.removeClass('loading');
            slider.slick('removeSlide', null, null, true);
            slider.slick('slickAdd', res);
        })

})

$(document).on('click', '.toggle-content .tab', function(){
    var elem = $(this)
    var tabs = elem.siblings()
    var index = elem.index()
    var wrapper = elem.parents('.toggle-content')
    var contentWrapper = wrapper.children('.tabs-content')
    var content = contentWrapper.children()

    tabs.removeClass('active')
    elem.addClass('active')
    content.removeClass('active')
    content.eq(index).addClass('active')
})