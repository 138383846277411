const sidebar = $('.page-sidebar')

if($('.filter-item').length){
      
    function get_filters(){
        var filters = {};
    
        $('.select-filter').each(function(){
            var elem = $(this),
                slug = elem.data('slug'),
                selected = elem.attr('data-id')

            if(selected){
                filters[slug] = selected;
            }
        })
    
        return filters;
    }
    
    function get_tax(){
        var wrapper = $('.page-wrapper'),
            tax = wrapper.data('tax'),
            val = wrapper.data('val'),
            results = '';
    
        if(tax && val){
            results = {
                tax,
                val
            };
    
            results[tax] = val;
        }
    
        return results;
    }
    
    function get_sort(){
        var sort = $('#sort').attr('data-id');
    
        if(sort){
            return sort
        }
    }
    
    $('#sort, .select-filter').change(get_results);
    
    function get_results(){
        var filters = get_filters(),
            tax = get_tax(),
            sort = get_sort(),
            results = {filters,tax, sort},
            wrapper = $('.page-wrapper'),
            content = $('.products-grid > .line')
    
        wrapper.addClass('loading');
        requestAjax('get_catalog_items', results, function(res){
            wrapper.removeClass('loading');
            content.html(res);
        })
    }

    if(sidebar.hasClass('load')){
        get_results();
    }

}