function closeDropdowns(){
    $('.dropdown, .select-dropdown').removeClass('show')
}

function appendOptions(elem, list){
    elem[0].options = list
}

function getOptions(elem, key){
    return elem[0].options[key]
}

function getOptionsList(elem, key, selected){
    var options = getOptions(elem, key),
        structure = ''

    if(options){
        $.each(options, function(key, value){

            if(selected && selected == key){
                structure += '<li class="active" data-id="' + key + '">' + value + '</li>'
            }else{
                structure += '<li data-id="' + key + '">' + value + '</li>'
            }
            
        })   
    }

    return structure
}

function resetSelect(elem, enable, selected){
    elem.removeAttr('data-id').val('')
    elem.next('.select-dropdown').find('li').removeClass('active')

    elem.parents('.form-group').removeClass('error success')

    if(enable){
        elem.removeAttr('disabled')
    }else{
        elem.attr('disabled', true)
    }

    if(selected && elem.hasClass('dynamic-list')){
        var dropdown = elem.next('.select-dropdown'),
            options = getOptionsList(elem, selected)

        dropdown.html(options)

        if(!options) {
            elem.attr('disabled', true)
        }
    }

    if(elem.hasClass('parent')){
        var child = elem.data('child'),
            child = $(child)

        resetSelect(child, false)
    }
}

$(document).on('change', '.toggle-blocks input', function(){
    var elem = $(this),
        items = elem.parents('.toggle-blocks').find('input'),
        index = items.index(elem),
        parent = elem.parents('.toggle-parent'),
        items = parent.find('.toggle')

    items.removeClass('dn').hide()
    items.eq(index).show()
})

$(document).on('focus', '.select-wrapper input', function(){
    var elem = $(this),
        parent = elem.parent(),
        dropdown = parent.find('.select-dropdown')

    dropdown.toggleClass('show')
    $(document).click(function (e){
        if (!parent.is(e.target) && parent.has(e.target).length === 0) {
            dropdown.removeClass('show')
        }
    })
})

$(document).on('input', '.select-dropdown li.search input', function(){
    var elem = $(this),
        val = elem.val().toLowerCase(),
        parent = elem.parents('li'),
        items = parent.siblings('li:not(.always-show)')

    items.each(function(){
        var elem = $(this),
            text = elem.find('span').text().toLowerCase()

        if(!text){
            text = elem.text()
        }

        if(text.indexOf(val) < 0){
            elem.hide()
        }else{
            elem.show()
        }

    })
})

$(document).on('click', '.select-dropdown li:not(.search)', function(){
    var elem = $(this),
        text = elem.find('span').text(),
        id = elem.attr('data-id'),
        wrapper = elem.parents('.select-wrapper'),
        dropdown = elem.parents('.select-dropdown'),
        input = wrapper.find('input').eq(0),
        func = input.data('func')

    if(!text){
        text = elem.text()
    }

    if(elem.hasClass('clear')){
        input.removeAttr('data-id').val('')
        elem.siblings().removeClass('active')

        if(func){
            window[func]('')
        }

    }else{
        input.val(text).attr('data-id', id)
        elem.addClass('active').siblings().removeClass('active')

        if(func){
            window[func](id)
        }
    }

    if(input.hasClass('parent')){
        var child = input.data('child'),
            child = $(child)

        resetSelect(child, true, id)
    }

    dropdown.removeClass('show')
    input.change()
})

$(document).on('change', '.form-group.mark input', function(){
    var elem = $(this),
        elem = elem.next(),
        active_index = elem.index(),
        siblings = elem.siblings('label')

    elem.addClass('active')

    siblings.each(function(){
        var elem = $(this),
            index = elem.index()

        if(index < active_index) {
            elem.addClass('active')
        }else{
            elem.removeClass('active')
        }
    })
})

var selected = []

$(document).on('change', '.form-group.file input', function(e){
    var elem = $(this),
        files = elem[0].files

    $.each(files, function(key, value){
        selected.push(value)
    })
})

$(document).on('click', '.toggler label:not([for])', function(){
    var checkbox = $(this).prev()
    checkbox.prop("checked", !checkbox.prop("checked"))
})

$(document).on('click', '.clone-input', function(e){
    e.preventDefault()

    var elem = $(this),
        max = elem.data('max'),
        parent = elem.parents('.form-group'),
        index = parent.attr('data-index')

    if(!index){
        index = 2
    }else{
        index++
    }
    
    parent.clone().insertAfter(parent)
    var clone = parent.next(),
        input = clone.find('input'),
        label = clone.find('label'),
        id = input.attr('id').replace(/[0-9]/gm, index)

    clone.attr('data-index', index)
    input.attr('id', id)
    label.attr('for', id)
    input.val('')

    if(index == max){
        clone.find('.clone-input').remove()
    }

    if(input.hasClass('phone-mask')){
        phone_mask()
    }
    elem.remove()
})

function clearInput(elem){
    elem.replaceWith(elem.val('').clone(true))
    elem.next('label').find('.avatar-image').remove()
}

$(document).on('change', '.avatar_input', function(e){
    var elem = $(this),
        label = elem.next('label'),
        file = elem[0].files[0],
        max_size = 4

    if(file){

        if(file.type == 'image/jpeg' || file.type == 'image/png'){

            if(file.size <= (max_size * 1048576)){
                var reader = new FileReader()

                reader.readAsDataURL(file)
                reader.onload = function(e) {
                    var image = e.target.result
                    label.append('<div class="avatar-image icon-close" style="background-image: url(' + image + ')"></div>')
                }

            }else{
                clearInput(elem)
                addNotif('Ошибка', 'Недопустимый формат файла', 'error')
            }

        }else{
            clearInput(elem)
            addNotif('Ошибка', 'Недопустимый формат файла', 'error')
        }

    }else{

    }

})