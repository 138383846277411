function headerScroll(){
    if($(window).scrollTop()){
        $('.fixed-btns').addClass('show')
    }else{
        $('.fixed-btns').removeClass('show')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$(document).on('click', '.open-menu', function(){
    $('.mobile-menu').addClass('show')
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-menu').removeClass('show')
})

$(document).click(function (e) {
    var menu = $('.menu-group')

    if ( !menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show')
    }
})

$('.toggle-theme').click(function(){
    var elem = $(this),
        html = $('html');

    if(html.hasClass('default')){
        html.removeClass('default').addClass('white');

        postAjax('setTheme', 'white');
    }else{
        html.removeClass('white').addClass('default');

        postAjax('setTheme', 'default');
    }
})

$('.user-block').click((e) => {
    const elem = $(e.target).parents('.user-block-wrapper')
    const dropdown = elem.find('.def-dropdown')

    dropdown
        .toggleClass('show')
})

$(document).click((e) => {
    const userBlock = $('.user-block-wrapper')
    const dropdown = userBlock.children('.def-dropdown')

    if ( !userBlock.is(e.target) && userBlock.has(e.target).length === 0) {
        dropdown
            .removeClass('show')
    }
})

setTimeout(function(){
    var popup = $('.products-popup')

    openPopup(popup)

    $('.products-slider').slick('setPosition')
}, 4000)