$(document).on('click', '[id*="ymaps"]', function(){
    const elem = $(this)

    elem.addClass('active')
})

$('.sidebar-section-title.toggle-block').click(function(){
    const elem = $(this)
    const content = elem.next()

    elem.toggleClass('hidden')
    content.stop().slideToggle(400)
})

if($(window).width() < 767){
    $('.sidebar-section-title.toggle-block').addClass('hidden').next().hide()
}