$(document).on('click', '.toggle-order-info', (e) => {
    const elem = $(e.target)
    const item = elem.parents('.history-item')
    const dropdown = item.find('.dropdown')

    dropdown
        .stop()
        .slideToggle(300)

    elem
        .toggleClass('active')
})